<template>
    <!--  注册-->
    <div class="registerMes loginDialog">
        <el-form
            ref="registerForm"
            :rules="registerRules"
            :label-position="labelPosition"
            :model="registerForm"
            class="demo-ruleForm"
            size="small"
            :hide-required-asterisk="true"
        >
            <div>Registration</div>
            <p>Register now and make the most of My Account. You will be able to:</p>
            <ul>
                <li>Receive our exclusive newsletter</li>
                <li>Save your favorite items</li>
                <li>Shop faster</li>
                <li>Check your orders and returns</li>
            </ul>
            <div class="requires">* Required fields</div>
            <el-form-item label="First Name *" prop="firstName">
                <el-input
                    v-model="registerForm.firstName"
                    placeholder="First Name"
                ></el-input>
            </el-form-item>
            <el-form-item label="Last Name *" prop="lastName">
                <el-input
                    v-model="registerForm.lastName"
                    placeholder="Last Name"
                ></el-input>
            </el-form-item>
            <el-form-item label="Email address *" prop="email">
                <el-input
                    v-model="registerForm.email"
                    placeholder="Your email address"
                ></el-input>
            </el-form-item>
            <el-form-item label="Password *(6 - 15 characters)" prop="password">
                <el-input
                    type="password"
                    v-model="registerForm.password"
                    placeholder="Password"
                ></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password *" prop="confirmPassword">
                <el-input
                    type="password"
                    v-model="registerForm.confirmPassword"
                    placeholder="Confirm Password"
                ></el-input>
            </el-form-item>
            <el-form-item label="Date of birth" prop="confPass" class="dateBirth">
                <div class="block">
                    <el-date-picker
                        v-model="dateValue"
                        type="date"
                        placeholder="Date of birth"
                        class="dateEletion">
                    </el-date-picker>
                </div>
            </el-form-item>
            <div class="pargs">
                This site is protected by reCAPTCHA and the Google
                <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link> and
                <a href="#">Terms of Service apply.</a>
            </div>
            <div class="detail">
                In order to enhance your experience on bingxu.com, personal data will be
                electronically processed by BING XU. Your data will be strictly
                controlled by BING XU for customer management, marketing and other
                purposes as detailed in our <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy.</router-link>
            </div>
            <div class="agrment">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox
                        label="I agree that BING XU may collect my personal information for marketing purposes (newsletters, updates and collection arrivals etc.)."
                    ></el-checkbox>
                    <el-checkbox
                        label="I consent to the processing of my personal data by BING XU for customer satisfaction purposes and for customizing my user experience to my interests or my shopping habits."
                    ></el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="registBtn" @click="confirmRegister('registerForm')">
                <button class="submit registerSub" type="button">Register</button>
            </div>
        </el-form>
    </div>
</template>
<script>
import {register} from "@/api/api";

export default {
    name: "register",
    components: {},
    data() {
        return {
            dateValue: "",
            currentSelectedCategoryId: null,
            ipt: false,
            showContent: false,
            forgetDrawer: false,
            registDrawer: false,
            direction: "rtl",
            forgetDir: "btt",
            regDirections: "rtl",
            labelPosition: "top",
            forgetPosition: "top",
            status: 1, //默认是登录弹窗
            isLogin: 11, //默认是修改密码窗口
            value: "",
            // 登陆表单
            formLabelAlign: {
                name: "",
                region: ""
            },
            formLabelAligns: {
                region: ""
            },
            // 返回登录
            backForm: {
                address: "",
                password: ""
            },
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                birthDay: "",
                birthMonth: "",
                birthYear: ""
            },
            checkList: [],
            registerRules: {
                firstName: [
                    {
                        required: true,
                        message: "The first name field is required",
                        trigger: "blur"
                    }
                ],
                lastName: [
                    {
                        required: true,
                        message: "The last name field is required",
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        type: "email",
                        required: true,
                        message: "The email address field is required",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "The password field is required",
                        trigger: "blur"
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "The confirm password field is required",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        forgetpwdFn() {
            this.forgetDrawer = true;
        },
        backForget() {
        },
        async confirmRegister(formName) {
            if (this.checkList.length < 2) {
                this.$message({
                    showClose: true,
                    message: 'Please check to agree to the relevant agreement',
                    type: "error"
                });
                return;
            }
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    if (this.dateValue) {
                        this.registerForm.birthYear = this.$moment(this.dateValue).format("YYYY");
                        this.registerForm.birthMonth = this.$moment(this.dateValue).format("MM");
                        this.registerForm.birthDay = this.$moment(this.dateValue).format("DD");
                    }
                    const {
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    } = this.registerForm;
                    const res = await register({
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    });
                    console.log(res);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: "Registered successful",
                            type: "success"
                        });
                        if (this.$route.path === "/register") {
                            this.$router.push({path: "/login"});
                        }
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

::v-deep .dateEletion .el-input__inner {
    margin-left: 1.5rem;
    width: 10rem;
}

::v-deep .dateEletion .el-input__prefix {
    left: -5px;
}

::v-deep .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: 0.5rem;
    height: 0.5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem 0 0;
}

::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #d8d7d5;
}

::v-deep .el-form-item__label {
    padding: 0;
    line-height: 0.1rem;
}

::v-deep .el-select {
    padding-right: 0.3rem;
}

::v-deep .el-select:last-child {
    padding-right: 0;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-tabs__nav-scroll {
    display: flex;
}


::v-deep .el-select-dropdown .el-popper {
    min-width: 5rem !important;
}

.dateBirth {
    ::v-deep .el-form-item__content {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;

        .el-select {
            padding-right: 0.8rem;
        }

        .el-select:last-child {
            padding-right: 0;
        }
    }
}

@media (min-width: 1280px) {
    .loginDialog {
        width: 27.5rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.loginDialog {
    a {
        border-bottom: 1px solid;
        padding-bottom: 0.125rem;
    }

    .requires {
        margin: 1rem 0;
    }

    .submit {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        display: block;
        padding: 0.4125rem;
        text-align: center;
        width: 80%;
        margin: 0 0 3rem 0;
    }

    .loginSub:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .registSub:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .registerSub:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        line-height: 0;
    }

    ::v-deep .el-form-item {
        margin-top: 1rem;
        padding-bottom: 1rem;
    }

    .pargs {
        margin: 2.9375rem 0 2.8125rem 0;
        font-size: 0.8125rem;
        line-height: 1.1875rem;
    }

    .detail {
        margin-bottom: 0.75rem;
        font-size: 0.8125rem;
        line-height: 1.1875rem;
    }

    .agrment {
        margin-bottom: 1rem;
    }
}

.registerMes {
    line-height: 1.1875rem;

    ul {
        display: flex;
        flex-direction: column;

        li {
            margin-left: 0;
            flex-direction: row;
            font-size: 0.8125rem;
            line-height: 1.1875rem;
            letter-spacing: 0.01562rem;
        }

        li:before {
            content: "·";
            margin-right: 3px;
        }
    }
}

@media (max-width: 1280px) {
    .registerMes {
        margin: 3.125rem 1.1875rem 0;

        .submit {
            width: 100% !important;
            padding: 1rem 3.125rem 0.9375rem !important;
        }
    }
}
</style>
